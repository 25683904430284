<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <div class="col-lg-12 control-section p-0">
      <div class="content-wrapper">
        <!-- <div>
          <ejs-dropdownlist
            id="dropdownlist"
            ref="dropdown"
            :data-source="ddData"
            width="220px"
            :fields="fields"
            :change="valueChange"
            :value="ddValue"
          />
          <span
            id="msg"
            ref="msgelement"
            v-html="loadTime"
          />
          <br>
        </div> -->
        <ejs-grid
          id="overviewgrid"
          ref="overviewgrid"
          :data-source="lottiSeriali"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :allow-filtering="true"
          :filter-settings="filterOptions"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="false"
          height="600"
          :allow-excel-export="true"
          :allow-pdf-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :load="load"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :allow-grouping="true"
          :allow-paging="true"
          :page-settings="pageSettings"
          :context-menu-items="contextMenuItems"
          :context-menu-click="contextMenuClick"
          :data-bound="dataBound"
          :row-data-bound="rowDataBound"
          :command-click="commandColumm"
          :allow-text-wrap="true"
        >

          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="60"
            />
            <e-column
              field="id"
              :visible="false"
              header-text="id"
              :is-primary-key="true"
              width="130"
            />
            <e-column
              field="idProdotto"
              header-text="Prodotto"
              width="200"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="lottoSeriale"
              header-text="lottto/seriale"
              width="170"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="giacenza"
              header-text="giacenza"
              width="230"
              :filter="filter"
            />
            <e-column
              field="unitaMisura"
              header-text="U.M."
              width="140"
              :filter="filter"
            />
            <e-column
              field="scadenza"
              header-text="scadenza"
              width="140"
              :filter="filter"
            />
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="240"
              :template="cTemplate"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
            />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
    <!-- <SettingsPdfExport
      :show="showModalSettingPdfExport"
      @pdf-export-confirm-close="closeModal"
    /> -->
    <modal-serial-batches-event-handler
      ref="serialbatches-modal-event-handler"
      @update-serial-batches="updateLottiToGrid"
    />
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate,
} from '@syncfusion/ej2-vue-grids'
// import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns'
import Ripple from 'vue-ripple-directive'
import { BCard, BBadge } from 'bootstrap-vue'
import moment from 'moment'
import { L10n, setCulture, Browser } from '@syncfusion/ej2-base'
import { getLottiSeriali } from '@/@core/api-service/logistic/lottiSeriali'
// import SettingsPdfExport from '@/components/pdfExportModal/SettingsPdfExport.vue'
import { alertProduct } from '@core/mixins/ui/alert'
import { italian } from '@/@core/grid-translation/it-IT'
import ModalSerialBatchesEventHandler from './eventHandlerSidebar/ModalSerialBatchesEventHandler.vue'

Vue.use(GridPlugin)
Vue.use(ToastPlugin)
//Vue.use(DropDownListPlugin)
setCulture('it-IT')
L10n.load(italian)

export default {
  directives: {
    Ripple,
  },
  components: {
    // BContainer,
    BCard,
    ModalSerialBatchesEventHandler,
  },
  mixins: [alertProduct],
  data() {
    return {
      lottiSeriali: [],
      rowModeMobile: 'Vertical',
      rowMode: 'Horizontal',
      wrapSettings: { wrapMode: 'Content' },
      // codeColumnSearch,
      isDesktop: !Browser.isDevice,
      puntiVendita: [],
      filterSettingsMobile: {
        type: 'Excel',
      },
      filterOptions: {
        type: 'Menu',
      },
      filter: {
        type: 'CheckBox',
      },
      selectionSettings: {
        persistSelection: false, type: 'Multiple', checkboxOnly: true,
      },
      commands: [
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
        {
          type: 'Dettagli',
          id: 'Dettagli',
          target: '.e-column',
          buttonOption: {
            iconCss: 'e-icons e-settings',
            cssClass: 'e-flat',
          },
        }],
      contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
        'Copy', 'Save', 'Cancel',
        'PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
        'LastPage', 'NextPage', 'Group', 'Ungroup',
        {
          text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
        },
        {
          text: 'Dettagli',
          target: '.e-content',
          id: 'Dettagli',
          iconCss: 'e-icons e-folder-open',
        },
      ],
      pageSettings: { pageSize: 25, pageSizes: [25, 50, 100, 200], pageCount: 5 },
      showModalSettingPdfExport: false,
      editSettings: {
        showDeleteConfirmDialog: false,
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        mode: 'Normal',
      },
      toolbarMobile: [{
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      'ColumnChooser'],
      toolbar: [{
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      'ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print', 'Search'], // { text: 'Dettagli', tooltipText: 'Dettagli', prefixIcon: 'e-expand', id: 'Dettagli' }
      cTemplate: function () {
          return { template : Vue.component('columnTemplate',{
            template: `<div class="image">
                  <img :src="image" :alt="altImage"/>
              </div>`,
              data: function() {
                  return {
                      data: {}
                  }
              },
            })
          }
        },
    }
  },
  computed: {
    getTradeData() {
      let dataCount = 50
      const employees = [
        'Michael', 'Kathryn', 'Tamer', 'Martin', 'Davolio', 'Nancy', 'Fuller', 'Leverling', 'Peacock',
        'Margaret', 'Buchanan', 'Janet', 'Andrew', 'Callahan', 'Laura', 'Dodsworth', 'Anne',
        'Bergs', 'Vinet', 'Anton', 'Fleet', 'Zachery', 'Van', 'King', 'Jack', 'Rose']
      const designation = ['Manager', 'CFO', 'Designer', 'Developer', 'Program Directory', 'System Analyst', 'Project Lead']
      const mail = ['sample.com', 'arpy.com', 'rpy.com', 'mail.com', 'jourrapide.com']
      const location = ['UK', 'USA', 'Sweden', 'France', 'Canada', 'Argentina', 'Austria', 'Germany', 'Mexico']
      const status = ['Active', 'Inactive']
      const trustworthiness = ['Perfect', 'Sufficient', 'Insufficient']
      const tradeData = []
      const address = ['59 rue de lAbbaye', 'Luisenstr. 48', 'Rua do Paço, 67', '2, rue du Commerce', 'Boulevard Tirou, 255',
        'Rua do mailPaço, 67', 'Hauptstr. 31', 'Starenweg 5', 'Rua do Mercado, 12',
        'Carrera 22 con Ave. Carlos Soublette #8-35', 'Kirchgasse 6',
        'Sierras de Granada 9993', 'Mehrheimerstr. 369', 'Rua da Panificadora, 12', '2817 Milton Dr.', 'Kirchgasse 6',
        'Åkergatan 24', '24, place Kléber', 'Torikatu 38', 'Berliner Platz 43', '5ª Ave. Los Palos Grandes', '1029 - 12th Ave. S.',
        'Torikatu 38', 'P.O. Box 555', '2817 Milton Dr.', 'Taucherstraße 10', '59 rue de lAbbaye', 'Via Ludovico il Moro 22',
        'Avda. Azteca 123', 'Heerstr. 22', 'Berguvsvägen  8', 'Magazinweg 7', 'Berguvsvägen  8', 'Gran Vía, 1', 'Gran Vía, 1',
        'Carrera 52 con Ave. Bolívar #65-98 Llano Largo', 'Magazinweg 7', 'Taucherstraße 10', 'Taucherstraße 10',
        'Av. Copacabana, 267', 'Strada Provinciale 124', 'Fauntleroy Circus', 'Av. dos Lusíadas, 23',
        'Rua da Panificadora, 12', 'Av. Inês de Castro, 414', 'Avda. Azteca 123', '2817 Milton Dr.']
      const employeeimg = ['usermale', 'userfemale']
      if (typeof dataCount === 'string') {
        dataCount = parseInt(dataCount, 10)
      }
      for (let i = 1; i <= dataCount; i++) {
        const code = 10000
        tradeData.push({
          id: code + i,
          codice:`${employees[Math.floor(Math.random() * employees.length)]} ${employees[Math.floor(Math.random() * employees.length)]}`,
          prodotto:`${employees[Math.floor(Math.random() * employees.length)]} ${employees[Math.floor(Math.random() * employees.length)]}`,
          lotto_seriale: designation[Math.floor(Math.random() * designation.length)],
          giacenza: location[Math.floor(Math.random() * location.length)],
          unita_misura: trustworthiness[Math.floor(Math.random() * trustworthiness.length)],
        })
      }
      return tradeData
    },
  },
  /* this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data }) */
  mounted() {
    getLottiSeriali().then(res => {
      console.log(res)
      if (res.data.esito === 'OK') {
        this.lottiSeriali = [...res.data.lottiSeriali]
      }
    }).catch(e => e)
  },
  methods: {
    onDeselectRow() {
      if (this.$refs.overviewgrid.getSelectedRecords().length === 0) {
        this.$refs.overviewgrid.ej2Instances.toolbarModule.enableItems(['overviewgrid_delete'], false)
      }
    },
    onRowSelected() {
      this.$refs.overviewgrid.ej2Instances.toolbarModule.enableItems(['overviewgrid_delete'], true)
      // console.log(this.selectedRow)
    },
    rowDataBound() {
    },
    actionTableBegin() {
    },
    actionTableComplete(args) {

    },
    updateLottiToGrid(shops, elementToDeleteWithNoID) {
      // console.log('shops', shops)
      let typeOperation = ''
      shops.forEach(element => {
        console.log('element', element)
        const index = this.$refs.overviewgrid.getRowIndexByPrimaryKey({ id: element.id})
        console.log(index)
        if(index >= 0) {
          // console.log('dentro index >0')
          this.$refs.overviewgrid.updateRow(index, element)
          typeOperation = 'Aggiornato/i'
          // this.$refs.overviewgrid.deleteRecord()
        } else {
          typeOperation = 'Aggiunto/i'
          this.$refs.overviewgrid.addRecord(element, 0)
        }
      })
      this.$refs.toastRef.show({
        title: 'Operazione Completata!', content: `'Lotto/i ${typeOperation} Correttamente'`, cssClass: 'e-toast-success', icon: 'e-success toast-icons',
      })
      this.$refs.overviewgrid.refresh()
      return
    },
    showDetails() {
      // console.log(params)
      this.$refs['serialbatches-modal-event-handler'].setTitle('Modifica Lotto Seriale')
      this.$refs['serialbatches-modal-event-handler'].openModal()
    },
    dataBound() {
      // console.log(this.$refs.overviewgrid)
      this.$refs.overviewgrid.autoFitColumns(['Azioni'])
    },
    commandColumm(args) {
      // console.log('commandColumm', args)
      if (args.commandColumn.type === 'Dettagli') {
        this.$refs['serialbatches-modal-event-handler'].setTitle('Modifica Lotto Seriale')
        this.$refs['serialbatches-modal-event-handler'].setLottoSerialeTemplate(args.rowData)
        this.$refs['serialbatches-modal-event-handler'].openModal()
      }
      if (args.commandColumn.type === 'Elimina') {
        // console.log([{ id: args.rowData.id }])
        this.alertAndConfirm([{ id: args.rowData.id }])
      }
    },
    contextMenuClick(args) {
      // console.log('ciao sono menu click', args)
      if (args.item.id === 'Dettagli' && 'rowData' in args.rowInfo) {
        this.$refs['serialbatches-modal-event-handler'].setTitle('Modifica Lotto Seriale')
        this.$refs['serialbatches-modal-event-handler'].setLottoSerialeTemplate(args.rowInfo.rowData)
        this.$refs['serialbatches-modal-event-handler'].openModal()

        // this.showDetails(args.rowInfo.rowData)
      } else if (args.item.id === 'DeleteCustom') {
        this.alertAndConfirm([{ id: args.rowInfo.rowData.id }])
      }
    },
    deteleShops(id) {
      let recordToDelete = []
      if (id === undefined) recordToDelete = this.$refs.overviewgrid.getSelectedRecords().map(el => ({ id: el.id }))
      else recordToDelete = id
      // console.log(recordToDelete, 'wow')
      /* axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify(recordToDelete),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Catalogo',
            metodo: 'deleteCataloghi',
            ACL: '',
          },
        }) */
        deletePuntoVendita(recordToDelete).then(res => {
        // console.log(res)
        // console.log('record', recordToDelete)
        if (res.data.esito === 'OK') {
          // console.log('res', res)
          recordToDelete.forEach(record => this.$refs.overviewgrid.deleteRecord('id', record))
          // mixins
          // this.productDeletedCorrectlyAlert()
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Punto/i Vendita Eliminato/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
        } else if(res.data.esito === 'KO') {
          // console.log('dentro ko')
          this.$refs.toastRef.show({
            title: 'Errore Cancellazione', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
          })
        }
      }).catch(e => e)
    },
    alertAndConfirm(id) {
      // console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'Verranno rimossi permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          this.deteleShops(id)
        }
      })
    },
    toolbarClick(args) {
      // console.log('toolbarClick', args)
      if (args.item.id === 'Dettagli') {
        // this.showDetails(args.rowData.id)
      }
      if (args.item.id === 'Aggiungi') {
        this.$refs['serialbatches-modal-event-handler'].setTitle('Aggiungi Lotto Seriale')
        this.$refs['serialbatches-modal-event-handler'].resetLottoSeriale()
        this.$refs['serialbatches-modal-event-handler'].openModal()
      }
      if (args.item.id === 'Elimina' && this.$refs.overviewgrid.getSelectedRecords().length > 0) {
        // console.log('delete')
        this.alertAndConfirm()
      }
      if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          //you can customize the name as per your requirement
          fileName: `Lotti_Seriali_${moment().format('DD-MM-YYYY')}.pdf`,
        }
        this.$refs.overviewgrid.pdfExport(exportProperties)
        // this.$refs.overviewgrid.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          //you can customize the name as per your requirement
          fileName: `Lotti_Seriali_${moment().format('DD-MM-YYYY')}.xlsx`,
        }
        this.$refs.overviewgrid.excelExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          //you can customize the name as per your requirement
          fileName: `Lotti_Seriali_${moment().format('DD-MM-YYYY')}.csv`,
        }
        this.$refs.overviewgrid.csvExport(exportProperties)
      }
    },
    valueChange(args) {
      this.$refs.dropdown.$el.ej2_instances[0].closePopup()
      this.$refs.overviewgrid.showSpinner()
      this.isDataChanged = true
      this.stTime = null
      const contentElement = this.$refs.overviewgrid.ej2Instances.contentModule.content
      this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage = 1
      contentElement.scrollLeft = 0
      contentElement.scrollTop = 0
      // console.log(this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage)
      this.stTime = performance.now()
      this.ddValue = args.value
      this.$refs.overviewgrid.hideSpinner()
    },
    load() {
      // const gObj = this.$refs.overviewgrid.$el.ej2_instances[0]
      if (!this.isDesktop) {
        this.$refs.overviewgrid.$el.ej2_instances[0].rowRenderingMode = 'Vertical'
      }
      /* const proxy = this
      this.$refs.overviewgrid.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgrid.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      }) */
    },
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate, Edit],
  },
}
</script>
