var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"serialbatches-modal-event-handler",attrs:{"id":"serialbatches-modal-event-handler","ok-only":"","ok-title":"Crea","centered":"","size":"md","backdrop":"","no-close-on-backdrop":true,"title":_vm.titleEventHandler},on:{"ok":function($event){$event.preventDefault();return _vm.handleOk($event)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"serialbatches-validaton-observer"},[_c('b-row',{},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Unità Utilizzata","label-for":"lotto-unit","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"unità","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"descrizione","clearable":false,"options":_vm.dizionarioUnitaMisura,"reduce":function (val) { return val.codice; },"placeholder":"unità di misura"},on:{"open":_vm.getUnitaMisura},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Nessuna Opzione disponibile. ")]}}],null,true),model:{value:(_vm.lottoSeriale.unitaMisura),callback:function ($$v) {_vm.$set(_vm.lottoSeriale, "unitaMisura", $$v)},expression:"lottoSeriale.unitaMisura"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Prodotto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Prodotto',"label-for":"prodotto-serial-batches","label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"value":"id","name":"prodotto-serial-batches","reduce":function (val) { return val.id; },"filterable":"","placeholder":"seleziona prodotto","options":_vm.prodotti},on:{"open":_vm.getProducts},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Ricerca prodotti...")])]}},{key:"loading",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Caricamento prodotti...")])]}},{key:"searching",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Sto cercando...")])]}},{key:"selected-option",fn:function(ref){
var nome = ref.nome;
return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[_c('strong',[_vm._v(_vm._s(nome))])])]}}],null,true),model:{value:(_vm.lottoSeriale.idProdotto),callback:function ($$v) {_vm.$set(_vm.lottoSeriale, "idProdotto", $$v)},expression:"lottoSeriale.idProdotto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',{},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Fornitore',"label-for":"fornitore-serial-batches","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Fornitore","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"value":"id","reduce":function (val) { return val.id; },"filterable":"","name":"fornitore-serial-batches","placeholder":"seleziona fornitore","options":_vm.optionFornitore},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Ricerca Fornitore...")])]}},{key:"loading",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Caricamento Fornitore...")])]}},{key:"searching",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Sto cercando...")])]}}],null,true),model:{value:(_vm.lottoSeriale.idFornitore),callback:function ($$v) {_vm.$set(_vm.lottoSeriale, "idFornitore", $$v)},expression:"lottoSeriale.idFornitore"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Produttore',"label-for":"produttore-serial-batches","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Produttore","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"value":"id","reduce":function (val) { return val.id; },"filterable":"","placeholder":"seleziona produttore","options":_vm.optionProduttore},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Ricerca Produttore...")])]}},{key:"loading",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Caricamento Produttore...")])]}},{key:"searching",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Sto cercando...")])]}}],null,true),model:{value:(_vm.lottoSeriale.idProduttore),callback:function ($$v) {_vm.$set(_vm.lottoSeriale, "idProduttore", $$v)},expression:"lottoSeriale.idProduttore"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Numero di lotto","label-for":"number-serial-batch","label-class":"font-weight-bolder"}},[_c('b-form-input',{attrs:{"id":"number-serial-batch","placeholder":"lotto/seriale"},model:{value:(_vm.lottoSeriale.lottoSeriale),callback:function ($$v) {_vm.$set(_vm.lottoSeriale, "lottoSeriale", $$v)},expression:"lottoSeriale.lottoSeriale"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Giacenza lotto","label-for":"giacenza-serial-batch","label-class":"font-weight-bolder"}},[_c('b-form-input',{attrs:{"id":"giacenza-serial-batch","placeholder":"giacenza"},model:{value:(_vm.lottoSeriale.giacenza),callback:function ($$v) {_vm.$set(_vm.lottoSeriale, "giacenza", $$v)},expression:"lottoSeriale.giacenza"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"d-flex"},[_c('a',{on:{"click":(function () { return _vm.showDate = !_vm.showDate; })}},[_vm._v(" Prodotto con scadenza? ")])]),(_vm.showDate || _vm.lottoSeriale.scadenza.length > 1)?_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"lottoSeriale-scadenza","name":"birth-date","config":_vm.configFlatPicker,"placeholder":'scadenza'},model:{value:(_vm.lottoSeriale.scadenza),callback:function ($$v) {_vm.$set(_vm.lottoSeriale, "scadenza", $$v)},expression:"lottoSeriale.scadenza"}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }