<template>
  <b-modal
    id="serialbatches-modal-event-handler"
    ref="serialbatches-modal-event-handler"
    v-model="show"
    ok-only
    ok-title="Crea"
    centered
    size="md"
    backdrop
    :no-close-on-backdrop="true"
    :title="titleEventHandler"
    @ok.prevent="handleOk"
  > <!-- {{ lottoSeriale }} -->
    <validation-observer ref="serialbatches-validaton-observer">
      <b-row class="">
        <b-col cols="6">
          <b-form-group
            label="Unità Utilizzata"
            label-for="lotto-unit"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="unità"
              rules="required"
            >
              <v-select
                v-model="lottoSeriale.unitaMisura"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="descrizione"
                :clearable="false"
                :options="dizionarioUnitaMisura"
                :reduce="val => val.codice"
                placeholder="unità di misura"
                @open="getUnitaMisura"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  Nessuna Opzione disponibile.
                </template>
              </v-select>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <validation-provider
            #default="{ errors }"
            name="Prodotto"
            rules="required"
          >
            <b-form-group
              :label="'Prodotto'"
              label-for="prodotto-serial-batches"
              label-class="font-weight-bolder"
            >
              <v-select
                v-model="lottoSeriale.idProdotto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                value="id"
                name="prodotto-serial-batches"
                :reduce="(val) => val.id"
                filterable
                placeholder="seleziona prodotto"
                :options="prodotti"
                @open="getProducts"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <div>Ricerca prodotti...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #loading="{ search, searching, loading }">
                  <div>Caricamento prodotti...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #searching="{ search, searching, loading }">
                  <div>Sto cercando...</div>
                </template>
                <template #selected-option="{ nome }">
                  <div style="display: flex; align-items: baseline">
                    <strong>{{ nome }}</strong>
                  </div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col md="6">
          <b-form-group
            :label="'Fornitore'"
            label-for="fornitore-serial-batches"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="Fornitore"
              rules="required"
            >
              <v-select
                v-model="lottoSeriale.idFornitore"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                value="id"
                :reduce="(val) => val.id"
                filterable
                name="fornitore-serial-batches"
                placeholder="seleziona fornitore"
                :options="optionFornitore"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <div>Ricerca Fornitore...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #loading="{ search, searching, loading }">
                  <div>Caricamento Fornitore...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #searching="{ search, searching, loading }">
                  <div>Sto cercando...</div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="'Produttore'"
            label-for="produttore-serial-batches"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="Produttore"
              rules="required"
            >
              <v-select
                v-model="lottoSeriale.idProduttore"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                value="id"
                :reduce="(val) => val.id"
                filterable
                placeholder="seleziona produttore"
                :options="optionProduttore"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <div>Ricerca Produttore...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #loading="{ search, searching, loading }">
                  <div>Caricamento Produttore...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #searching="{ search, searching, loading }">
                  <div>Sto cercando...</div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col
          md="6"
        >
          <b-form-group
            label="Numero di lotto"
            label-for="number-serial-batch"
            label-class="font-weight-bolder"
          >
            <b-form-input
              id="number-serial-batch"
              v-model="lottoSeriale.lottoSeriale"
              placeholder="lotto/seriale"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            label="Giacenza lotto"
            label-for="giacenza-serial-batch"
            label-class="font-weight-bolder"
          >
            <b-form-input
              id="giacenza-serial-batch"
              v-model="lottoSeriale.giacenza"
              placeholder="giacenza"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <p class="d-flex">
            <a
              @click="(() => showDate = !showDate)"
            >
              Prodotto con scadenza?
            </a>
          </p>
          <flat-pickr
            v-if="showDate || lottoSeriale.scadenza.length > 1"
            id="lottoSeriale-scadenza"
            v-model="lottoSeriale.scadenza"
            class="form-control"
            name="birth-date"
            :config="configFlatPicker"
            :placeholder="'scadenza'"
          />
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BModal, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import { getCombo } from '@/@core/api-service/shared/combo'
import { getProdotti } from '@/@core/api-service/catalog/products'
import { setLottiSeriali } from '@/@core/api-service/logistic/lottiSeriali'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      showDate: false,
      locale: 'it',
      show: false,
      titleEventHandler: '',
      lottoSeriale: {
        id: 0,
        codice: '',
        idProdotto: '',
        idFornitore: '',
        idProduttore: '',
        lottoSeriale: '',
        giacenza: '',
        scadenza: '',
        unitaMisura: '',
      },
      dizionarioUnitaMisura: [],
      optionFornitore: [{ id: 1, nome: 'Default' }],
      optionProduttore: [{ id: 1, nome: 'Default' }],
      prodotti: [],
      defaultDate: '2021-02-20',
      configFlatPicker: {
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'Y-m-d',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
      },
    }
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    getProducts() {
      getProdotti().then(res => {
        if (res.data.esito === 'OK') {
          this.prodotti = [...res.data.prodotti]
          // console.log(this.optionProdottiAssociati)
        }
      }).catch(e => e)
    },
    getUnitaMisura() {
      getCombo().then(res => {
        if (res.data.esito === 'OK') {
          this.dizionarioUnitaMisura = [...res.data.unitaMisura]
        }
      }).catch(e => e)
    },
    handleOk() {
      this.$refs['serialbatches-validaton-observer'].validate().then(success => {
        if (success) {
          // console.log('ciaooo')
          // console.log('ciao', this.lottoSeriale)
          setLottiSeriali([{ ...this.lottoSeriale }]).then(res => {
            // console.log(res)
            if (res.data.esito === 'OK') {
              this.$emit('update-serial-batches', [...res.data.lottiSerialiAggiunte, ...res.data.lottiSerialiAggiornate])
              // this.$emit('update-serial-batches', res.data.lottiSerialiAggiornate)
              this.resetLottoSeriale()
              this.$refs['serialbatches-modal-event-handler'].hide()
            }
          }).catch(e => e)
        }
      }).catch(e => e)
    },
    setLottoSerialeTemplate(lotto) {
      this.lottoSeriale = { ...lotto }
    },
    openModal() {
      this.show = true
    },
    setTitle(title) {
      this.titleEventHandler = title
    },
    resetLottoSeriale() {
      this.lottoSeriale = {
        id: 0,
        codice: '',
        idProdotto: '',
        idFornitore: '',
        idProduttore: '',
        lottoSeriale: '',
        giacenza: '',
        scadenza: '',
        unitaMisura: '',
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
